<template>
<div class="container-fluid p-dash-cliente-master pb-2 pt-3" style="margin-top: 100px">
    <div class="col-12">
        <div class="row">
            <div class="col-auto align-self-center text-right pr-0">
                <a class="btn btn-success rounded-circle btn-circle font-20 card-body-list-cta" href="?app=chamados.php"><i class="fas fa-arrow-left"></i></a>
            </div>
            <div class="col-10 align-self-center">
                <h3 class="page-title text-truncate text-dark font-weight-medium mb-1 d-none">Editando chamado</h3>
                <div class="d-flex align-items-center">
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb m-0 p-0">
                               <li class="breadcrumb-item"><router-link :to="{name: 'Dashboard'}">dashboard</router-link> / <router-link :to="{name: 'Report'}">assistência técnica</router-link> / {{ report.subject }}</li>
                        </ol>
                    </nav>
                </div>
            </div>
        </div>
    </div>
    <div class="col-12 pt-1 px-0"> 
        <div class="col-12">
            <div class="row">
                <div class="col-12 col-md-12 col-lg-9 pt-3 order-2 order-md-1 order-lg-1">
                    <div class="card border-right card-bg-dash">
                        <div class="card-body">
                        <form class="px-2 needs-validation" novalidate @submit.prevent="save">
                                <div class="form-body">
                                    <div class="row">
                                        <div class="col-12">
                                            <BaseClientBox v-model="report.client_id" />
                                        </div>
                                        <div class="col-12 col-md-12 col-lg-12 pt-3">
                                            <div class="form-group">
                                                <label>Assunto </label>
                                                <input type="text" id="inputSuccess1" class="form-control" v-model="report.subject" placeholder="Identificação do chamado" :disabled="report.status == 'FINISHED'">
                                                <div class="invalid-feedback pl-4 pb-3">Por favor! Informe o assunto.</div>
                                            </div>
                                        </div>
                                        <div class="col-12 pb-2">
                                            <BaseEnterpriseBox v-model="report.enterprise_id"/>
                                        </div>
                                        <div class="col-12 pt-3"></div>
                                        <div class="col-12 pb-2">
                                            <BaseCondBox v-model="report.cond" />
                                        </div>
                                        <div class="col-12 pt-3"></div>
                                        <div class="col-12 pb-2">
                                            <BaseAptBox v-model="report.floors" :enterprise_id="report.enterprise_id" :disabled="report.status == 'FINISHED'"  />

                                        </div>
                                        <div class="col-12 pt-3"></div>
                                        <div class="col-12 col-md-4 col-lg-4">
                                            <div class="form-group">
                                                <label>N˚ torre</label>
                                                <b-form-select v-model="report.tower" v-validate="'required'" name="tower" :class="{'is-invalid': errors.has('tower')}" :options="towers" :disabled="report.status == 'FINISHED'"></b-form-select>
                                            </div>
                                        </div>
                                        <div class="col-12 col-md-4 col-lg-4">
                                            <div class="form-group">
                                                <label>Qual status do chamado? </label>
                                                <select class="custom-select mr-sm-2" v-model="report.status" :disabled="report.status == 'FINISHED'">
                                                    <option :value="null">Selecione</option>
                                                    <option value="PENDING">Novo</option>
                                                    <option value="IN_PROGRESS">Em andamento</option>
                                                    <option value="FINISHED">Resolvido</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-12 col-md-4 col-lg-4">
                                            <div class="form-group">
                                                <label>Não conformidades</label>
                                                <select class="custom-select mr-sm-2" id="inlineFormCustomSelect" :disabled="report.status == 'FINISHED'">
                                                    <option selected="">Selecione</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-12 col-md-4 col-lg-4">
                                            <div class="form-group">
                                                <label>Procedentes</label>
                                                <b-form-select v-model="report.precedent_id" :options="precedents" v-validate="'required'" name="precedent" :class="{'is-invalid': errors.has('precedent')}" :disabled="report.status == 'FINISHED'"></b-form-select>
                                            </div>
                                        </div>
                                        <div class="col-12 col-md-4 col-lg-4">
                                            <div class="form-group">
                                                <label>Data/Hora agendamento </label>
                                                <input type="datetime-local" class="form-control" v-model="report.date" :disabled="report.status == 'FINISHED'">
                                            </div>
                                        </div>
                                        <div class="col-12 pb-2">
                                            <label>Técnico responsável </label>
                                            <input type="text" class="form-control"  v-model="report.technical.first_name" :disabled="report.status == 'FINISHED'">

                                        </div>
                                        <div class="col-12 col-md-12 col-lg-12 pt-4">
                                            <div class="form-group">
                                                <label>Observação </label>
                                                <textarea class="form-control" rows="6" placeholder="Mensagem" v-model="report.description" :disabled="report.status == 'FINISHED'"></textarea>
                                                <small id="textHelp" class="form-text text-muted">Informe detalhes sobre o chamado.</small>
                                            </div>
                                        </div>
                                        <div class="col-12 col-md-12 col-lg-12">
                                            <vue-upload-multiple-image
                                                @upload-success="uploadImageSuccess"
                                                @before-remove="beforeRemove"
                                                @edit-image="editImage"
                                                :data-images="report.images"
                                                idUpload="myIdUpload"
                                                editUpload="myIdEdit"
                                                dragText="Clique ou arraste uma image aqui"
                                                browseText="Procurar imagem"
                                                :showPrimary="false"
                                                :showEdit="false">
                                            </vue-upload-multiple-image>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-actions">
                                    <div class="text-right">
                                        <button type="reset" class="btn btn-dark mr-2" :disabled="report.status == 'FINISHED'">Cancelar</button>
                                        <button type="submit" class="btn btn-info" :disabled="report.status == 'FINISHED'">Atualizar</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-12 col-lg-3 px-0 order-1 order-md-2 order-lg-2">
                    <Detail :report="report" @update="fetchReport" />
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import ReportService from "@/services/resources/ReportService";
import Detail from '@/components/Reports/Detail';
import EnterpriseService from "@/services/resources/EnterpriseService";
import ConfigService from "@/services/resources/ConfigService";
import VueUploadMultipleImage from "vue-upload-multiple-image";
import moment from 'moment';

const serviceReport = ReportService.build();
const serviceEnterprise = EnterpriseService.build();
const serviceConfig = ConfigService.build();

export default {
    components:{
        Detail,
        VueUploadMultipleImage
    },
    data(){
        return{
            report:{
                open_like: 'CLIENT',
                client_id: null,
                client: {
                    first_name: null,
                    last_name: null
                },
                conds: [],
                enterprise_id: null,
                enterprise: {
                    name: null
                },
                subject: null,
                tower: null,
                status: 'PENDING',
                conformity_id: null,
                conformity:{
                    name: null
                },
                precedent_id: null,
                technical_id: null,
                technical:{
                    first_name: null,
                    last_name: null
                },
                description: null,
                date: null,
                floors: []
            },
            answer: null,
            towers: [
                { value: null, text: 'Selecione' }
            ],
            floors: [],
            conformities: [
                { value: null, text: 'Selecione' }
            ],
            precedents: [
                { value: null, text: 'Selecione' }
            ],
            images: []
        }
    },
    filters:{
      date(date){
          return moment(date).format('DD/MM/YYYY H:MM');
      }
    },
    methods:{
        uploadImageSuccess(formData, index, fileList) {
            this.images = fileList;
        },
        beforeRemove(index, done, fileList) {
            var r = confirm("Remover imagem?");
            if (r == true) {
                this.images.splice(index, 1);
                done();
            }
        },
        editImage(formData, index, fileList) {
            this.images = fileList;
        },
        generateGrounds(grounds){
            let total = 0;
            for(let i = 0; i < grounds.length; i++){
                total += grounds[i].apt_numbers * grounds[i].floors;
            }

            return total;
        },
        getOpenLike(open_like){

            switch(open_like){
                case 'LIQUIDATOR':
                    return 'Síndico';
                case 'TECHNICAL':
                    return 'Técnico';
                case 'LESSE':
                    return 'Locatário';
                case 'CLIENT':  
                    return 'Cliente';
                default:
                    return '';
            }

        },
        async fetchConfig(type){
            let data = {
            type: type
            }
            return serviceConfig.search(data).then();
        },
        async selectEnterprise(id){
            let enterprise = await serviceEnterprise.read({id: id}).then();
            this.towers = [
                { value: null, text: 'Selecione' }  
            ];
            this.towers = this.towers.concat(new Array(enterprise.towers).fill().map((e,i) => {
                return {value: i+1, text: i+1}
            }));

            this.floors = this.floors.concat(new Array(this.generateGrounds(enterprise.grounds)).fill().map((e,i) => {
                return {value: i + 1, text: i + 1}
            }));

        },
        fetchReport(){

            if(!this.id){
                return;
            }

            let data = {
                id: this.id
            };

            serviceReport
            .read(data)
            .then(resp => {
                this.report = resp;
                this.report.date = moment(this.report.date).format('YYYY-MM-DD[T]HH:mm');
                this.selectEnterprise(this.report.enterprise_id);
                this.report.conds = resp.conds.map(elem => {
                    return elem.config_id
                });
                this.report.floors = resp.floors.map(elem => {
                    return elem.apt_number
                });


            })
            .catch(err => {
                console.log(err)
            })
        },
        save(){

            this.$validator.validateAll().then((result) => {
                if (result) {

                    let report = Object.assign(this.report);
                    report.id = this.id;
                    report.date = moment(report.date).format("YYYY-MM-DD H:mm");
                    serviceReport
                    .update(report)
                    .then(resp => {
                        this.$bvToast.toast('Seu chamado foi atualizado com sucesso!', {
                            title: 'Chamado atualizado',
                            autoHideDelay: 5000,
                            type: 'success'
                        });
                        
                    })
                    .catch(err => {
                        console.log(err)
                    })
                }
            });
        },
    },
    async mounted(){
        this.id = this.$route.params.id;
        this.fetchReport();

        var resp = await this.fetchConfig('PRECEDENTS');
        this.precedents = this.precedents.concat(resp.map(elem => {
            return {
                value: elem.id,
                text: elem.name
            }
        }))

    }
}
</script>
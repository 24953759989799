<template>
<div class="col-12">
    <div class="col-12">
        <div class="form-group pt-3">
            <h3 class="page-title text-truncate text-dark font-weight-medium mb-1">interações</h3>
        </div>
        <div class="form-group">
            <button type="button" class="btn btn-success concluir-chamado" v-if="report.status != 'FINISHED' && showBtn" @click="update"><i class="far fa-thumbs-up font-18 align-middle ml-1" ></i> Encerrar atendimento</button>
        </div>
        <div class="form-group" v-if="report.date">
            <label>Data da visita técnica </label>
            <div><b>{{ report.date | date }}</b></div>
        </div>
        <div class="form-group" v-for="item in report.answers" :key="item.id">
            <label class="pl-0">{{ item.created_at | date }}</label>
            <div class="text-content-exibir"><b>Sr(a).</b> {{ item.user.first_name }} {{ item.user.last_name }}</div>
            <div>{{ item.answer }}</div>
            <div class="py-1"><i class="fas fa-image"></i> Imagens: <a :href="item.image" target="_blank" class="a-link-1-img" title="Imagem" v-if="item.image">01</a>
            </div>
        </div>
        <div class="col-12 pb-2 pt-0 px-0" v-if="report.images && report.images.length > 0">
            <p class="page-txt-view-chamado">Fotos anexadas</p>
            <b-carousel
                id="carousel-1"
                :interval="4000"
                controls
                indicators
                background="#ababab"
                img-width="1024"
                img-height="480"
                style="text-shadow: 1px 1px 2px #333;">
                <b-carousel-slide v-for="item in report.images" :key="item.id" :img-src="item.path"></b-carousel-slide>
            </b-carousel>

        </div>
    </div>
</div>
</template>

<script>
import ReportService from "@/services/resources/ReportService";
import moment from 'moment';

const service = ReportService.build();

export default {
    props:{
        report:{
            type: Object
        }
    },
    data(){
        return{
            showBtn: true
        }
    },
    filters:{
      date(date){
          return moment(date).format('DD/MM/YYYY H:MM');
      }
    },
    methods:{
        update(){

            let data = {
                id: this.report.id,
                status: 'FINISHED'
            }

            service
            .update(data)
            .then(resp => {
                this.$bvToast.toast('Chamado encerrado!', {
                    title: 'Chamado',
                    autoHideDelay: 5000,
                    type: 'success'
                });
                this.showBtn = false;
                this.$emit('update');
            })
            .catch(err => {
                console.log(err)
            })
        }
    },
}
</script>